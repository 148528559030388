






import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs
} from '@vue/composition-api'
import E from 'wangeditor'

export default defineComponent({
  name: 'WangEditor',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const state = reactive({
      contentHtml: '', //html格式的内容
      contentText: '' //text格式的内容
    })
    const editor = ref<HTMLElement | null>()

    let instance = ref()

    onMounted(() => {
      instance.value = new E(editor.value)
      Object.assign(instance.value.config, {
        onchange() {
          // console.log('change')
        }
      })

      instance.value.create()
      instance.value.txt.html(props.content)
      instance.value.disable()
    })

    onBeforeUnmount(() => {
      instance.value.destroy()
      instance.value = null
    })

    return {
      ...toRefs(state),
      editor
    }
  }
})
